import { setupWorker, rest } from 'msw'

// Mock configuration data
const mockConfig = {
  REACT_APP_CONTENTFUL_URL: 'https://preview.contentful.com',
  REACT_APP_CONTENTFUL_TOKEN: 'phv1U5N-x9ogCuBJtVfjm2yR0YTRy6103dyMj1oB5Tk',
  REACT_APP_CLINICAL_URL: 'https://localhost:8000/api/v1',
  REACT_APP_MOCK_API: 'http://localhost:3001',
  REACT_APP_ORDER_SERVICE_DOWN: 'false',
  REACT_APP_CLIENT_ID: 'test',
}

const getEndpoints = {
  cases: 'cases?enquiry=',
  config: 'config',
}

const handleCaseRequest = (req, res, ctx) => {
  const statusCode = 401 as number // Set the desired status code for the response

  let errorMessage
  switch (statusCode) {
    case 401:
      errorMessage = 'Unauthorized: User is not authenticated'
      break
    case 404:
      errorMessage = 'Not found: The requested resource does not exist'
      break
    case 409:
      errorMessage = "Conflict: Sample can't be found in the database"
      break
    default:
      errorMessage = 'Internal server error: Something went wrong on the server'
  }

  return res(ctx.status(statusCode), ctx.json({ message: errorMessage }))
}

const handleConfigRequest = (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(mockConfig)) // Respond with the mock config data
}

const worker = setupWorker(
  /* rest.get(`${REACT_APP_CLINICAL_URL}/${getEndpoints.cases}`, handleCaseRequest), */
  rest.get(`/${getEndpoints.config}`, handleConfigRequest)
)

worker.start()
