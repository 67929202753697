import React from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import { supportSystemURL } from '../../services/helpers/constants'
import { useAuth } from 'react-oidc-context'

export const ErrorPage = ({ error }) => {
  const auth = useAuth()
  switch (error?.response?.status) {
    case 404:
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" key="console">
              <Link to="/">Back to Home</Link>
            </Button>
          }
        />
      )
    case 401:
      auth.removeUser()
      return null
    default:
      return (
        <Result
          title={'Something went wrong'}
          extra={
            <div>
              Try again soon and if the problem persists open a ticket in our{' '}
              <a href={`${supportSystemURL}home`}>support system</a>
            </div>
          }
        />
      )
  }
}
