import React from 'react'
import { Avatar } from 'antd'

const getInititals = (fullName: string): string[] =>
  fullName?.split(' ').map((fragment) => fragment[0])

export const UserAvatar = ({ user }) => (
  <Avatar
    size={'large'}
    style={{
      color: '#ffffff',
      backgroundColor: '#00A3E0',
    }}
  >
    {getInititals(user?.name)}
  </Avatar>
)
