import { useContext } from 'react'
import { AppConfigContext } from 'components/AppConfigContext'

const IS_HOSPITAL = process.env.REACT_APP_IS_HOSPITAL === 'true'
const CLINICAL_URL = process.env.REACT_APP_CLINICAL_URL || ''
const CONTENTFUL_URL = process.env.REACT_APP_CONTENTFUL_URL || ''
const CONTENTFUL_TOKEN = process.env.REACT_APP_CONTENTFUL_TOKEN || ''
const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_CLIENT_ID || ''
const KEYCLOAK_AUTHORITY = process.env.REACT_APP_AUTHORITY || ''
const KEYCLOAK_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || ''
const IS_ORDER_SERVICE_DOWN = process.env.REACT_APP_ORDER_SERVICE_DOWN === 'true'

export const useFeatureFlags = () => {
  const config = useContext(AppConfigContext)

  const getConfigValue = (key: keyof typeof process.env, fallback: string) =>
    IS_HOSPITAL ? config?.[key] ?? fallback : fallback

  const getBooleanConfig = (key: keyof typeof process.env, fallback: boolean) =>
    IS_HOSPITAL ? config?.[key] === 'true' : fallback

  return {
    IS_HOSPITAL,
    clinicalUrl: getConfigValue('REACT_APP_CLINICAL_URL', CLINICAL_URL),
    contentfulUrl: getConfigValue('REACT_APP_CONTENTFUL_URL', CONTENTFUL_URL),
    contentfulToken: getConfigValue('REACT_APP_CONTENTFUL_TOKEN', CONTENTFUL_TOKEN),
    isServiceDown: getBooleanConfig('REACT_APP_ORDER_SERVICE_DOWN', IS_ORDER_SERVICE_DOWN),
    keycloakClientID: getConfigValue('REACT_APP_CLIENT_ID', KEYCLOAK_CLIENT_ID),
    keycloakAuthority: getConfigValue('REACT_APP_AUTHORITY', KEYCLOAK_AUTHORITY),
    keycloakRedirectURI: getConfigValue('REACT_APP_REDIRECT_URI', KEYCLOAK_REDIRECT_URI),
  }
}
