import React from 'react'
import { Card, Typography, Space, Divider } from 'antd'
import styles from './ContactUs.module.css'

const { Title, Text, Paragraph } = Typography

export const ContactUs = ({ contacts }) => {
  return (
    <Card>
      <div className={styles.contactUs}>
        <Title level={2}>Clinical Genomics Stockholm</Title>
        <Card className={styles.contactInfoSection} bordered={false}>
          <Title level={3}>For general questions</Title>
          <Paragraph>
            <Text strong>Phone:</Text> +46 85 248 15 00
          </Paragraph>
          <Paragraph>
            <Text strong>Email:</Text>{' '}
            <a href="mailto:support@clinicalgenomics.se">support@clinicalgenomics.se</a>
          </Paragraph>
        </Card>

        <div className={styles.contactGrid}>
          {contacts?.map((post, i) => {
            const { info, name, phoneNumber, email, title } = post.fields
            return (
              <Card key={i} className={styles.contactCard} bordered={false}>
                <Title level={5}>{info}</Title>
                <Space direction="vertical" size={0.2}>
                  <Divider />
                  <Paragraph>
                    <Text className={styles.contactCardSubtitle}>{name}</Text>
                  </Paragraph>
                  <Paragraph>
                    <Text>Phone: {phoneNumber}</Text>
                  </Paragraph>
                  <Paragraph>
                    <a href={`mailto:${email}`}>{email}</a>
                  </Paragraph>
                  <Paragraph>{title}</Paragraph>
                </Space>
              </Card>
            )
          })}
        </div>
      </div>
    </Card>
  )
}
