import React from 'react'
import styles from '../Forms.module.css'
import { Button, Divider, Row } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import {
  buffers,
  carrierStatus,
  controls,
  getAvailableContainersType,
  plateLayout,
  tissueBlockSize,
} from 'services/helpers/constants'
import { isBufferRequired } from 'pages/OrderForm/helpers'
import { SampleNameField } from '../Fields/SampleNameField'
import { SexField } from '../Fields/SexField'
import { ControlField } from '../Fields/ControlField'
import { ReqQCField } from '../Fields/ReqQcField'
import { ApplicationField } from '../Fields/ApplicationField'
import { SubjectIdField } from '../Fields/SubjectIdField'
import { AgeField } from '../Fields/AgeField'
import { PhenotypeGroupsField } from '../Fields/PhenotypeGroupsField'
import { PhenotypeTermsField } from '../Fields/PhenotypeTermsField'
import { CommentField } from '../Fields/CommentField'
import { ElutionBufferField } from '../Fields/ElutionBufferField'
import { SourceField } from '../Fields/SourceField'
import { FormalinFixationHoursField } from '../Fields/FormalinFixationHoursField'
import { WeeksAfterFixationField } from '../Fields/WeeksAfterFixationField'
import { TissueBlockField } from '../Fields/TissueBlockField'
import { SourceCommentField } from '../Fields/SourceCommentField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { VolumeField } from '../Fields/VolumeField'
import { WellPositionField } from '../Fields/WellPositionField'
import { ConcentrationField } from '../Fields/ConcentrationField'
import { StatusField } from '../Fields/StatusField'
import { MotherField } from '../Fields/MotherField'
import { FatherField } from '../Fields/FatherField'
import { DeletePopConfirm } from 'components/DeletePopConfirm'

export const TomteSampleForm = ({
  index,
  isExisting,
  setSampleId,
  analysisType,
  buildParentsObject,
  sampleId,
  setApplication,
  skipReceptionControl,
  applicationTags,
  form,
  caseName,
  subjectId,
  application,
  options,
  prefillConditionalFields,
  setContainerNameRequirement,
  hasContainer,
  containerNameRequirement,
  isPlate,
  isFormalineSource,
  isOtherSource,
  deleteSample,
  parentsSamples,
  caseFieldsValidation,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          buildParentsObject={buildParentsObject}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
          standaloneFieldsValidation={null}
        />
        <StatusField
          isExisting={isExisting}
          index={index}
          carrierStatus={carrierStatus}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
          standaloneFieldsValidation={null}
        />
        <SexField
          index={index}
          isExisting={isExisting}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
          standaloneFieldsValidation={null}
        />
        <ControlField
          index={index}
          isExisting={isExisting}
          controls={controls}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
          standaloneFieldsValidation={null}
        />
        {!isExisting && (
          <ReqQCField
            index={index}
            isExisting={isExisting}
            form={form}
            caseName={caseName}
            caseFieldsValidation={caseFieldsValidation}
            standaloneFieldsValidation={null}
          />
        )}
        {isExisting && (
          <>
            <ApplicationField
              index={index}
              isExisting={isExisting}
              setApplication={setApplication}
              applicationTags={applicationTags}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
            <SubjectIdField
              subjectId={subjectId}
              required={!isExisting}
              index={index}
              isExisting={isExisting}
              caseName={caseName}
              form={form}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
          </>
        )}
      </Row>
      <Row className={styles.row}>
        <MotherField
          index={index}
          parentsSamples={parentsSamples}
          sampleId={sampleId}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
        />
        <FatherField
          index={index}
          parentsSamples={parentsSamples}
          sampleId={sampleId}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
          standaloneFieldsValidation={null}
        />
      </Row>
      {!isExisting && (
        <>
          <Row className={styles.row}>
            <SubjectIdField
              subjectId={subjectId}
              index={index}
              required={!isExisting}
              isExisting={isExisting}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
            <ApplicationField
              index={index}
              isExisting={isExisting}
              setApplication={setApplication}
              applicationTags={applicationTags}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
            <AgeField
              index={index}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
          </Row>
          <Row className={styles.row}>
            <PhenotypeGroupsField
              index={index}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
            />
            <PhenotypeTermsField
              index={index}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
            />
            <CommentField
              index={index}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
          </Row>
          <Divider style={{ marginTop: '-2px' }} />
          <Row className={styles.row}>
            <ElutionBufferField
              index={index}
              isBufferRequired={isBufferRequired}
              application={application}
              buffers={buffers}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
            <SourceField
              index={index}
              prefillConditionalFields={prefillConditionalFields}
              options={options}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
            {isFormalineSource && (
              <>
                <FormalinFixationHoursField
                  index={index}
                  form={form}
                  caseName={caseName}
                  caseFieldsValidation={caseFieldsValidation}
                />
                <WeeksAfterFixationField
                  index={index}
                  form={form}
                  caseName={caseName}
                  caseFieldsValidation={caseFieldsValidation}
                />
                <TissueBlockField
                  index={index}
                  tissueBlockSize={tissueBlockSize}
                  form={form}
                  caseName={caseName}
                  caseFieldsValidation={caseFieldsValidation}
                />
              </>
            )}
            {isOtherSource && (
              <SourceCommentField
                index={index}
                form={form}
                caseName={caseName}
                caseFieldsValidation={caseFieldsValidation}
                standaloneFieldsValidation={null}
              />
            )}
          </Row>
          <Row className={styles.row}>
            <ContainerField
              index={index}
              prefillConditionalFields={prefillConditionalFields}
              setContainerNameRequirement={setContainerNameRequirement}
              getAvailableContainersType={getAvailableContainersType}
              analysisType={analysisType}
              required={true}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
            {hasContainer && (
              <>
                <ContainerNameField
                  index={index}
                  containerNameRequirement={containerNameRequirement}
                  isRML={false}
                  form={form}
                  caseName={caseName}
                  caseFieldsValidation={caseFieldsValidation}
                  standaloneFieldsValidation={null}
                />{' '}
                <VolumeField
                  index={index}
                  form={form}
                  caseName={caseName}
                  caseFieldsValidation={caseFieldsValidation}
                  standaloneFieldsValidation={null}
                />
              </>
            )}
            {isPlate && (
              <WellPositionField
                index={index}
                plateLayout={plateLayout}
                isRML={false}
                form={form}
                caseName={caseName}
                caseFieldsValidation={caseFieldsValidation}
                standaloneFieldsValidation={null}
              />
            )}
            <ConcentrationField
              index={index}
              skipReceptionControl={skipReceptionControl}
              form={form}
              caseName={caseName}
              caseFieldsValidation={caseFieldsValidation}
              standaloneFieldsValidation={null}
            />
          </Row>
        </>
      )}
      <DeletePopConfirm
        itemType={'sample'}
        action={deleteSample}
        itemName={caseName}
        triggerComponent={
          <Button icon={<DeleteTwoTone />} block>
            Sample
          </Button>
        }
      />
    </>
  )
}
