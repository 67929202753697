import React from 'react'
import { Button, Flex } from 'antd'
import { LogoutOutlined, LoginOutlined } from '@ant-design/icons'
import { UserAvatar } from 'components/Avatar/Avatar'
import { useOidcAuth } from 'hooks/useOidcAuth'

export const AuthButton = () => {
  const auth = useOidcAuth()

  if (auth.isAuthenticated) {
    return (
      <Flex gap="large" align="center">
        <UserAvatar user={auth.user} />
        <Button
          type="text"
          icon={<LogoutOutlined style={{ fontSize: '16px' }} />}
          iconPosition={'start'}
          onClick={() => auth.logout()}
          style={{ color: '#fff', fontSize: '16px' }}
        >
          Log out
        </Button>
      </Flex>
    )
  }

  return (
    <Button
      type="text"
      icon={<LoginOutlined style={{ fontSize: '16px' }} />}
      iconPosition={'start'}
      onClick={() => auth.login()}
      style={{ color: '#fff', fontSize: '16px' }}
    >
      Log in
    </Button>
  )
}
