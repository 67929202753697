import React from 'react'
import { AuthProvider } from 'react-oidc-context'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

export const AuthProviderWrapper = ({ children }) => {
  const { keycloakClientID, keycloakAuthority, keycloakRedirectURI } = useFeatureFlags()

  const oidcConfig = {
    authority: keycloakAuthority,
    client_id: keycloakClientID,
    redirect_uri: keycloakRedirectURI,
  }

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>
}
