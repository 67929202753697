import React, { useContext } from 'react'
import { Card, Descriptions, Tag, Typography } from 'antd'
import { UserAvatar } from '../../components/Avatar/Avatar'
import { UserContext } from '../../services/contexts/userContext'

export const AccountPage = () => {
  const userContext = useContext(UserContext)
  const { user } = userContext
  const { Title } = Typography

  const customers = [
    {
      agreement_date: null,
      agreement_registration: null,
      comment:
        '240311: Added "is trusted" /AG\r\n220606-VW: Added invoicing address and updated also other fields.',
      data_archive_location: 'PDC',
      delivery_contact_id: 126,
      id: 1,
      internal_id: 'cust000',
      invoice_address: 'Karolinska Institutet\r\nFakturor\r\nBox 23109\r\n104 35 Stockholm',
      invoice_contact_id: 1,
      invoice_reference: 'ZZC1VALWIR',
      is_clinical: false,
      is_trusted: true,
      lab_contact_id: null,
      loqus_upload: false,
      name: 'Clinical Genomics Stockholm',
      organisation_number: '202100-2973',
      primary_contact_id: 1,
      priority: 'research',
      project_account_ki: 'C1 34 101 063',
      project_account_kth: '80205',
      return_samples: false,
      scout_access: true,
      uppmax_account: null,
    },
  ]

  return (
    <>
      {user?.name && (
        <Card style={{ marginBottom: 20 }}>
          <Descriptions title={<UserInfo userInfo={user} />}>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
          </Descriptions>
          <br />
          {customers?.map((customer) => (
            <Descriptions title={<Title level={5}>{customer.name}</Title>} key={customer.id}>
              <Descriptions.Item label="id">{customer.internal_id}</Descriptions.Item>
              <Descriptions.Item label="Invoice reference">
                {customer.invoice_reference}
              </Descriptions.Item>
              <Descriptions.Item label="Organisation number">
                {customer.organisation_number}
              </Descriptions.Item>
              <Descriptions.Item label="Invoice address">
                {customer.invoice_address}
              </Descriptions.Item>
            </Descriptions>
          ))}
        </Card>
      )}
    </>
  )
}

export const UserInfo = ({ userInfo }) => {
  const { Text } = Typography
  return (
    <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
      <UserAvatar user={userInfo} />
      <Text strong>{userInfo.name} </Text>
      {userInfo.is_admin && <Tag color={'blue'}>Admin</Tag>}
    </div>
  )
}
