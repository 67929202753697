import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../services/contexts/userContext'
import { Card, Descriptions, Tag, Skeleton } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { ErrorNotification, formatDate } from 'services/helpers/helpers'
import { getTypeTagColor } from 'services/helpers/styleHelpers'
import { useApi } from '../../services/StatusDbApi'

export const SamplePage = () => {
  const userContext = useContext(UserContext)
  const { pathname } = useLocation()
  const sampleId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)
  const [customerSample, setCustomerSample] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const api = useApi()

  useEffect(() => {
    api
      .getSample(userContext, sampleId)
      .then((res) => {
        setCustomerSample(res)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      })
      .catch(({ response }) => {
        ErrorNotification('Could not load the page', response.status)
      })
  }, [userContext, sampleId, api])

  return (
    <Card>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 5 }} />
      ) : (
        <Descriptions title={customerSample?.name} bordered column={5}>
          <Descriptions.Item label="Internal ID" span={2}>
            {customerSample?.internal_id}
          </Descriptions.Item>
          <Descriptions.Item label="Customer name" span={2}>
            {customerSample?.customer?.name} ({customerSample?.customer?.internal_id})
          </Descriptions.Item>
          <Descriptions.Item label="Order">{customerSample?.order}</Descriptions.Item>
          <Descriptions.Item label="Case" span={2}>
            <Link to={`/cases/${customerSample?.links[0]?.family?.internal_id}`}>
              {customerSample?.links[0]?.family?.name}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label="Tag" span={2}>
            <Link to={`/applications`}>{customerSample?.application?.tag}</Link>
          </Descriptions.Item>
          <Descriptions.Item label="Prep category">
            {customerSample?.application?.prep_category && (
              <Tag color={getTypeTagColor(customerSample?.application?.prep_category)}>
                {customerSample?.application?.prep_category.toUpperCase()}
              </Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Ordered">
            {customerSample?.ordered_at ? formatDate(customerSample?.ordered_at) : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Received">
            {customerSample?.received_at ? formatDate(customerSample?.received_at) : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Sequenced">
            {customerSample?.last_sequenced_at ? formatDate(customerSample?.last_sequenced_at) : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Delivered">
            {customerSample?.delivered_at ? formatDate(customerSample?.delivered_at) : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Invoiced">
            {customerSample?.invoiced_at ? formatDate(customerSample?.invoiced_at) : ''}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  )
}
