import react from 'react'
import axios from 'axios'
import { UserContext } from './contexts/userContext'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

export const useApi = () => {
  const { clinicalUrl } = useFeatureFlags()
  const axiosGETContext = (endPoint: string, { token }: UserContext) => {
    return axios
      .get(endPoint, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => response.data)
  }

  const axiosPOSTContext = (endPoint: string, { token }: UserContext, data: any) => {
    return axios
      .post(endPoint, data, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => response.data)
  }

  const axiosGET = (endPoint: string, token: string) => {
    return axios
      .get(endPoint, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => response.data)
  }

  // API call methods
  const getCases = async (
    context: UserContext,
    queryString = '',
    pagination: { current: number; pageSize: number },
    action = ''
  ): Promise<any> => {
    const { current, pageSize } = pagination
    const queryParams = new URLSearchParams()

    if (queryString) {
      queryParams.append('enquiry', queryString)
    }
    queryParams.append('pageSize', pageSize.toString())
    queryParams.append('page', current.toString())

    if (action) {
      queryParams.append('action', action)
    }

    const endPoint = `${clinicalUrl}/cases?${queryParams.toString()}`
    return axiosGETContext(endPoint, context)
  }

  const getCase = async (context: UserContext, caseId: string): Promise<any> => {
    const endPoint = `${clinicalUrl}/cases/${caseId}`
    return axiosGETContext(endPoint, context)
  }

  const getUser = async (token: string): Promise<any> => {
    const endPoint = `${clinicalUrl}/me`
    return axiosGET(endPoint, token)
  }

  const getSamples = async (
    context: UserContext,
    queryString = '',
    pagination: { current: number; pageSize: number }
  ): Promise<any> => {
    const { current, pageSize } = pagination
    const queryParams = new URLSearchParams()

    if (queryString) {
      queryParams.append('enquiry', queryString)
    }
    queryParams.append('pageSize', pageSize?.toString())
    queryParams.append('page', current?.toString())

    const endPoint = `${clinicalUrl}/samples?${queryParams.toString()}`
    return axiosGETContext(endPoint, context)
  }

  const getSample = async (context: UserContext, sampleId: string): Promise<any> => {
    const endPoint = `${clinicalUrl}/samples/${sampleId}`
    return axiosGETContext(endPoint, context)
  }

  const getPools = async (context: UserContext, query_string = ''): Promise<any> => {
    const endPoint = `${clinicalUrl}/pools?enquiry=${query_string}`
    return axiosGETContext(endPoint, context)
  }

  const getApplicationTags = async (context: UserContext, orderType: string): Promise<any> => {
    const endPoint = `${clinicalUrl}/applications/order_type?order_type=${orderType}`
    return axiosGETContext(endPoint, context)
  }

  const getApplications = async (context: UserContext): Promise<any> => {
    const endPoint = `${clinicalUrl}/applications`
    return axiosGETContext(endPoint, context)
  }

  const getApplication = async (context: UserContext, appId: string): Promise<any> => {
    const endPoint = `${clinicalUrl}/applications/${appId}`
    return axiosGETContext(endPoint, context)
  }

  const getOptions = async (context: UserContext): Promise<any> => {
    const endPoint = `${clinicalUrl}/options`
    return axiosGETContext(endPoint, context)
  }

  const getCollaborationCases = async (
    context: UserContext,
    customer: string,
    orderType: string
  ): Promise<any> => {
    const endPoint = `${clinicalUrl}/families_in_collaboration?customer=${customer}&data_analysis=${orderType}`
    return axiosGETContext(endPoint, context)
  }

  const postOrder = async (context: UserContext, orderType: string, body: any): Promise<any> => {
    const endPoint = `${clinicalUrl}/submit_order/${orderType}`
    return axiosPOSTContext(endPoint, context, body)
  }

  const getExistingSamples = async (
    context: UserContext,
    customer: string,
    input = ''
  ): Promise<any> => {
    const endPoint = `${clinicalUrl}/samples_in_collaboration?enquiry=${input}&customer=${customer}`
    return axiosGETContext(endPoint, context)
  }

  const getExistingCases = async (
    context: UserContext,
    customer: string,
    input = '',
    workflow = ''
  ): Promise<any> => {
    const endPoint = `${clinicalUrl}/families_in_collaboration?enquiry=${input}&customer=${customer}&data_analysis=${workflow}`
    return axiosGETContext(endPoint, context)
  }

  const validateOrder = async (
    context: UserContext,
    orderType: string,
    body: any
  ): Promise<any> => {
    const endPoint = `${clinicalUrl}/validate_order/${orderType}`
    return axiosPOSTContext(endPoint, context, body)
  }

  return {
    getCases,
    getCase,
    getUser,
    getSamples,
    getSample,
    getPools,
    getApplicationTags,
    getApplications,
    getApplication,
    getOptions,
    getCollaborationCases,
    postOrder,
    getExistingSamples,
    getExistingCases,
    validateOrder,
  }
}
