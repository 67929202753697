import React from 'react'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { createRoot } from 'react-dom/client'
import AppConfigProvider from 'components/AppConfigContext'
import { AuthProviderWrapper } from 'components/AuthProviderWrapper'

const container = document.getElementById('root')
const root = createRoot(container)

if (process.env.REACT_APP_MOCK_API === 'true') {
  require('./mocks/browser')
}

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#005883',
        fontFamily: '"Open Sans", Arial, sans-serif',
      },
      components: {
        Layout: {
          headerBg: '#005883',
        },
        Menu: {
          darkItemBg: '#005883',
          darkItemColor: '#ffffff',
          darkItemHoverColor: '#00A3E0',
          darkItemSelectedColor: '#00A3E0',
        },
      },
    }}
  >
    <BrowserRouter>
      <AppConfigProvider>
        <AuthProviderWrapper>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </AuthProviderWrapper>
      </AppConfigProvider>
    </BrowserRouter>
  </ConfigProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
serviceWorker.unregister()
