import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './Forms.module.css'
import { Button, Card, Row, Spin, Col, Tag } from 'antd'
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'
import {
  buffers,
  getAvailableContainersType,
  controls,
  extractionMethods,
  isClinical,
  isMetagenome,
  isMicrobialFastq,
  isMicrobialWGS,
  isRML,
  isSarsCovid,
  labs,
  plateLayout,
  preprocessingMethods,
  primers,
  priorities,
  regions,
  selectionCriteria,
  isTaxprofiler,
  isPacBio,
  otherOrganism,
} from '../../services/helpers/constants'
import {
  extractStandaloneValues,
  getBackgroundColor,
  getIsPlate,
  isBufferRequired,
} from '../../pages/OrderForm/helpers'
import { DeletePopConfirm } from '../DeletePopConfirm'
import { indexSequences } from '../../services/helpers/indexSequences'
import { getIndexSequence } from '../../services/helpers/helpers'
import { UserContext } from '../../services/contexts/userContext'
import { MicrobialWGSForm } from './StandaloneOrderForms/MicrobialWGSForm'
import { MicrobialFastqForm } from './StandaloneOrderForms/MicrobialFastqForm'
import { MetagenomeWGSForm } from './StandaloneOrderForms/MetagenomeWGSForm'
import { RmlFluffyForm } from './StandaloneOrderForms/RmlFluffyForm'
import { SarsCov2Form } from './StandaloneOrderForms/SarsCov2Form'
import { ClinicalForm } from './StandaloneOrderForms/ClinicalForm'
import { TaxprofilerForm } from './StandaloneOrderForms/TaxprofilerForm'
import { PacBioForm } from './StandaloneOrderForms/PacBioForm'
import { useApi } from '../../services/StatusDbApi'

export const StandaloneSampleForm = ({
  index,
  remove,
  options,
  form,
  analysisType,
  skipReceptionControl,
  applicationTags,
}) => {
  const [isFinalized, setIsFinalized] = useState<boolean>(false)
  const [finalizedSample, setFinalizedSample] = useState<any>()
  const [hasContainer, setHasContainer] = useState(false)
  const [isPlate, setIsPlate] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sampleId, setSampleId] = useState<string>()
  const [organisms] = useState<any[]>(options?.organisms)
  const [indexSequence, setIndexSequence] = useState<string>('')
  const [application, setApplication] = useState<string>()
  const [isOtherSource, setIsOtherSource] = useState(false)
  const [isOtherOrganism, setIsOtherOrganism] = useState(false)
  const [hasIndex, setHasIndex] = useState(true)
  const [containerNameRequirement, setContainerNameRequirement] = useState<boolean>(false)
  const userContext = useContext(UserContext)
  const api = useApi()
  const prefillConditionalFields = useCallback(() => {
    const {
      container,
      name,
      application,
      source,
      tumour,
      index: indexName,
      index_number,
      organism,
    } = form.getFieldValue('samples')[index] || ''
    setIndexSequence(getIndexSequence(indexName, index_number))
    setSampleId(name?.value)
    setHasIndex(indexName?.value !== 'NoIndex')
    setApplication(application?.value)
    setIsOtherOrganism(organism?.value === otherOrganism)
    setIsPlate(getIsPlate(container?.value))
    setHasContainer(!container?.value?.includes('No container'))
    setContainerNameRequirement(container?.value === '96 well plate')
    if (source?.value) {
      setIsOtherSource(source?.value?.includes('other'))
    }
    if (!tumour?.value) form.setFieldValue(['samples', index, 'tumour'], false)
  }, [form, index])

  const finalizeSample = useCallback(
    async ({ errorFields }: any) => {
      let hasSampleError = false
      const localSample = form.getFieldValue('samples')[index]
      errorFields?.map(({ name }) => {
        if (name[1] === index) {
          hasSampleError = true
          setIsFinalized(false)
        }
      })
      setIsFinalized(!hasSampleError)
      setFinalizedSample(localSample)
    },
    [index, form]
  )

  useEffect(() => {
    prefillConditionalFields()
  }, [prefillConditionalFields])

  useEffect(() => {
    const localSample = form.getFieldsValue().samples[index]
    if (Object.keys(localSample).length > 1) {
      validateMountSamples()
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateSaveSample = useCallback(() => {
    setIsLoading(true)
    const order = form.getFieldsValue()
    const extractedOrderValues = extractStandaloneValues(order)
    const hasSamples = order.samples.filter((sample) => sample.name).length > 0
    if (hasSamples) {
      api.validateOrder(userContext, analysisType, extractedOrderValues).then((response) => {
        form.setFieldsValue(response)
        form
          .validateFields()
          .then(() => finalizeSample([]))
          .catch((errors) => finalizeSample(errors))
          .finally(() => setIsLoading(false))
      })
    } else {
      setIsLoading(false)
    }
  }, [userContext, finalizeSample, form, analysisType, api])

  const standaloneFieldsValidation = useCallback(() => {
    const order = form.getFieldsValue()
    const extractedOrderValues = extractStandaloneValues(order)

    const containsErrorKey = (obj: Record<string, any>): boolean => {
      return Object.entries(obj || {}).some(([key, value]) => {
        if (key === 'errors') return true
        if (typeof value === 'object' && value !== null) {
          return containsErrorKey(value)
        }
        return false
      })
    }

    const sampleHasError = containsErrorKey(order?.samples?.[index])
    if (!sampleHasError) return

    api
      .validateOrder(userContext, analysisType, extractedOrderValues)
      .then((response) => {
        form.setFieldsValue(response)
        return form.validateFields()
      })
      .catch(() => setIsLoading(false))
  }, [userContext, form, analysisType, index, api])

  const validateMountSamples = useCallback(() => {
    setIsLoading(false)
    form
      .validateFields()
      .then(() => {
        finalizeSample([])
        setIsLoading(false)
      })
      .catch((errors) => {
        finalizeSample(errors)
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalizeSample])

  return (
    <Card
      bodyStyle={{
        backgroundColor: getBackgroundColor(index),
      }}
      style={{ margin: 3, width: '100%' }}
      id={`sample ${index}`}
    >
      {isFinalized ? (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col flex="300px">
              <h2>{finalizedSample?.name?.value}</h2>
            </Col>
            <Col flex="auto">
              <Tag
                color={
                  priorities.find((priority) => priority.value === finalizedSample?.priority?.value)
                    ?.tagColor
                }
              >
                {priorities.find((priority) => priority.value === finalizedSample?.priority?.value)
                  ?.text || finalizedSample?.priority?.value}
              </Tag>
            </Col>
            <Col flex="150px">
              <div className={styles.savedCaseColumn}>
                <Button icon={<EditTwoTone />} onClick={() => setIsFinalized(false)}>
                  Edit
                </Button>
                <DeletePopConfirm
                  itemType={'sample'}
                  action={() => {
                    remove(index)
                  }}
                  itemName={sampleId}
                  triggerComponent={
                    <Button icon={<DeleteTwoTone />} block>
                      Sample
                    </Button>
                  }
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Spin tip="Validating samples..." size="small" spinning={isLoading}>
            {isMicrobialWGS(analysisType) && (
              <MicrobialWGSForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                controls={controls}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                setIsOtherOrganism={setIsOtherOrganism}
                form={form}
                organisms={organisms}
                isOtherOrganism={isOtherOrganism}
                buffers={buffers}
                extractionMethods={extractionMethods}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isMicrobialFastq(analysisType) && (
              <MicrobialFastqForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                controls={controls}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                form={form}
                buffers={buffers}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isMetagenome(analysisType) && (
              <MetagenomeWGSForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                controls={controls}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                form={form}
                buffers={buffers}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                options={options}
                isOtherSource={isOtherSource}
                skipReceptionControl={skipReceptionControl}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isTaxprofiler(analysisType) && (
              <TaxprofilerForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                controls={controls}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                form={form}
                buffers={buffers}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                options={options}
                isOtherSource={isOtherSource}
                skipReceptionControl={skipReceptionControl}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isRML(analysisType) && (
              <RmlFluffyForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                controls={controls}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                form={form}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                indexSequences={indexSequences}
                indexSequence={indexSequence}
                hasIndex={hasIndex}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isSarsCovid(analysisType) && (
              <SarsCov2Form
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                controls={controls}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                setIsOtherOrganism={setIsOtherOrganism}
                form={form}
                organisms={organisms}
                isOtherOrganism={isOtherOrganism}
                buffers={buffers}
                extractionMethods={extractionMethods}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                isBufferRequired={isBufferRequired}
                preprocessingMethods={preprocessingMethods}
                primers={primers}
                regions={regions}
                selectionCriteria={selectionCriteria}
                labs={labs}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isClinical(analysisType) && (
              <ClinicalForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                skipReceptionControl={skipReceptionControl}
                applicationTags={applicationTags}
                form={form}
                buffers={buffers}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                setIsTumour={null}
                isBufferRequired={isBufferRequired}
                application={application}
                options={options}
                isOtherSource={isOtherSource}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}

            {isPacBio(analysisType) && (
              <PacBioForm
                index={index}
                setSampleId={setSampleId}
                analysisType={analysisType}
                priorities={priorities}
                validateSample={validateSaveSample}
                setApplication={setApplication}
                applicationTags={applicationTags}
                form={form}
                prefillConditionalFields={prefillConditionalFields}
                setContainerNameRequirement={setContainerNameRequirement}
                getAvailableContainersType={getAvailableContainersType}
                hasContainer={hasContainer}
                containerNameRequirement={containerNameRequirement}
                isPlate={isPlate}
                plateLayout={plateLayout}
                remove={remove}
                sampleId={sampleId}
                buildParentsObject={null}
                setIsTumour={null}
                application={application}
                options={options}
                isOtherSource={isOtherSource}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}
          </Spin>
        </>
      )}
    </Card>
  )
}
