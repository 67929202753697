import React, { ReactElement, useEffect, useState, createContext } from 'react'
import axios from 'axios'
import { AppConfigContextState } from 'services/contexts/types'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

const contextDefaultValues: AppConfigContextState = {
  REACT_APP_CONTENTFUL_URL: '',
  REACT_APP_CONTENTFUL_TOKEN: '',
  REACT_APP_CLINICAL_URL: '',
  REACT_APP_ORDER_SERVICE_DOWN: '',
  REACT_APP_CLIENT_ID: '',
  REACT_APP_AUTHORITY: '',
  REACT_APP_REDIRECT_URI: '',
}

type Props = {
  children: ReactElement
}

export const AppConfigContext = createContext<AppConfigContextState>(contextDefaultValues)

const AppConfigProvider = ({ children }: Props) => {
  const { IS_HOSPITAL } = useFeatureFlags()
  const [appConfigContextState, setAppConfigContextState] = useState<AppConfigContextState>()

  useEffect(() => {
    if (!IS_HOSPITAL) {
      return
    }

    const fetchConfig = async () => {
      try {
        const res = await axios.get<AppConfigContextState>('/config.json')
        setAppConfigContextState(res.data)
        console.log('/config.json')
        console.log(res.data)
      } catch (err) {
        console.error('Failed to load config from endpoint:', err)
        try {
          const res = await axios.get<AppConfigContextState>('/config')
          setAppConfigContextState(res.data)
          console.log('/config')
          console.log(res.data)
        } catch (jsonErr) {
          console.error('Failed to load config from /config:', jsonErr)
        }
      }
    }

    fetchConfig()
  }, [IS_HOSPITAL])

  if (!IS_HOSPITAL) {
    return <>{children}</>
  }

  return appConfigContextState ? (
    <AppConfigContext.Provider value={appConfigContextState}>{children}</AppConfigContext.Provider>
  ) : null
}

export default AppConfigProvider
