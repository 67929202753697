import React from 'react'
import styles from './HospitalHeader.module.css'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import Logo from '../../assets/karolinska-logo-sv.svg'
import { AuthButton } from 'components/AuthButton/AuthButton'

export const HospitalHeader = ({ pages }) => {
  const { Header } = Layout
  const topMenuItems = [
    {
      key: '/',
      label: (
        <Link to={'/'}>
          <span>Home</span>
        </Link>
      ),
    },
    ...pages.map((page) => ({
      key: `/${page?.fields?.title?.route}`,
      label: (
        <Link to={page?.fields?.title?.route}>
          <span>{page?.fields?.title?.title}</span>
        </Link>
      ),
    })),
    {
      key: '/applications',
      label: (
        <Link to={'/applications'}>
          <span>Applications</span>
        </Link>
      ),
    },
    {
      key: '/contact-us',
      label: (
        <Link to={'/contact-us'}>
          <span>Contact Us</span>
        </Link>
      ),
    },
  ]
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={Logo} alt="logo" />
          </div>
        </div>
      </div>
      <Header className={styles.header}>
        <Menu mode="horizontal" items={topMenuItems} className={styles.menu} theme="dark" />
        <div className={styles.authButton}>
          <AuthButton />
        </div>
      </Header>
    </>
  )
}
