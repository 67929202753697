import * as React from 'react'
import { User } from '../interfaces'

export interface UserContext {
  token: string | null
  user: User | null
}

export const UserContext = React.createContext<UserContext>({
  token: null,
  user: null,
})
