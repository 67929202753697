import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../services/contexts/userContext'
import { Card, Descriptions, Skeleton } from 'antd'
import { useLocation } from 'react-router-dom'
import { ErrorNotification, formatDate } from 'services/helpers/helpers'
import { CaseSamplesTable } from '../../components/CaseSamplesTable/CaseSamplesTable'
import { CompletedAnalysesTable } from 'components/CompletedAnalysesTable/CompletedAnalysesTable'
import { useApi } from '../../services/StatusDbApi'

export const CasePage = () => {
  const userContext = useContext(UserContext)
  const { pathname } = useLocation()
  const caseId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)
  const [customerCase, setCustomerCase] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const api = useApi()

  useEffect(() => {
    api
      .getCase(userContext, caseId)
      .then((res) => {
        setCustomerCase(res)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      })
      .catch(({ response }) => ErrorNotification('Could not load the page', response.status))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <>
          <Card>
            <Descriptions title={customerCase?.name} bordered column={3}>
              <Descriptions.Item label="Customer name">
                {customerCase?.customer?.name} ({customerCase?.customer?.internal_id})
              </Descriptions.Item>
              <Descriptions.Item label="Ordered at">
                {formatDate(customerCase?.ordered_at)}
              </Descriptions.Item>
              <Descriptions.Item label="Gene panels">{customerCase?.panels}</Descriptions.Item>
            </Descriptions>
          </Card>
          {customerCase?.links && <CaseSamplesTable caseLinks={customerCase.links} />}
          {customerCase?.analyses && customerCase.analyses.length > 0 && (
            <CompletedAnalysesTable analyses={customerCase.analyses} />
          )}
        </>
      )}
    </>
  )
}
