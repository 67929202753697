import React from 'react'
import { Form, Select, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ReferenceGenomeField = ({
  index,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFunction = caseFieldsValidation || standaloneFieldsValidation
  return (
    <>
      <Form.Item
        label={
          <div>
            <a
              href="https://www.ncbi.nlm.nih.gov/genome/browse/#!/overview/"
              target="_blank"
              rel="noreferrer"
            >
              Reference genome
            </a>
          </div>
        }
        name={[index, 'reference_genome', 'value']}
        rules={[
          { required: true },
          {
            validator: () => serverValidator('reference_genome', form, caseName, index),
          },
        ]}
      >
        <Input
          style={{ width: 150 }}
          placeholder="e.g. NC_001928"
          onChange={() => validationFunction()}
        />
      </Form.Item>
    </>
  )
}
