import React, { useContext, useEffect, useState } from 'react'
import styles from './App.module.css'
import './index.css'
import { Layout, Menu, Card, Button, Typography, Image, Divider, FloatButton } from 'antd'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import Logo from './assets/logo.svg'
import './App.less'
import Footer from './components/Footer/Footer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Loading } from './components/Loading'
import { BLOCKS } from '@contentful/rich-text-types'
import { HomePage } from './pages/Home/HomePage'
import { getContent } from './services/ContentfulApi'
import { ErrorNotification, getCookies, resetCookies, setCookie } from './services/helpers/helpers'
import { AccountPage } from './pages/Account/AccountPage'
import { acceptedCookieName, language } from './services/helpers/constants'
import { CookieConsent } from './components/CookieConsent/CookieConsent'
import { isNil } from 'ramda'
import { ClinicalCookies, User } from './services/interfaces'
import { UserContext } from 'services/contexts/userContext'
import { CasesPage } from 'pages/Cases/CasesPage'
import { CasePage } from 'pages/Case/CasePage'
import { Unauthorized } from './components/Unauthorized'
import { SamplesPage } from './pages/Samples/SamplesPage'
import { SamplePage } from 'pages/Sample/SamplePage'
import { PoolsPage } from './pages/Pools/PoolsPage'
import {
  DatabaseOutlined,
  DotChartOutlined,
  ExperimentOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { ApplicationsPage } from './pages/Applications/ApplicationsPage'
import { ApplicationPage } from './pages/Application/ApplicationPage'
import { OrderFormPage } from './pages/OrderForm/OrderFormPage'
import { ContactUs } from 'components/ContactUs/ContactUs'
import { OrderServiceFailure } from 'pages/Error/OrderServiceFailure'
import { useOidcAuth } from 'hooks/useOidcAuth'
import { AuthButton } from 'components/AuthButton/AuthButton'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { Header } from 'components/Header/Header'

const { Content, Sider } = Layout
const { Title } = Typography
export const App = () => {
  const [pages, setPages] = useState<any[]>([])
  const [contacts, setContacts] = useState(null)
  const [assets, setAssets] = useState<any[]>([])
  const [isContentfulLoading, setIsContentfulLoading] = useState<boolean>(true)
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false)
  const [acceptedCookiesPolicy, setAcceptedCookiesPolicy] = useState<boolean>(true)
  const [isCookieDialogVisible, setIsCookieDialogVisible] = useState<boolean>(true)
  const location = useLocation()
  const { user, token, isAuthenticated, isLoading } = useOidcAuth()
  const { contentfulUrl, contentfulToken, isServiceDown } = useFeatureFlags()

  useEffect(() => {
    const cookies: ClinicalCookies = getCookies()
    setAcceptedCookiesPolicy(!isNil(cookies[acceptedCookieName]))
  }, [])

  const acceptCookies = () => {
    setAcceptedCookiesPolicy(true)
    setCookie(true, acceptedCookieName)
    setIsCookieDialogVisible(false)
  }
  const rejectCookies = () => {
    setAcceptedCookiesPolicy(false)
    setIsCookieDialogVisible(false)
    resetCookies()
  }

  const sideMenuItems = [
    {
      key: '/new-order',
      icon: <PlusCircleOutlined />,
      label: (
        <Link to={'/new-order'}>
          <span>New order</span>
        </Link>
      ),
    },
    {
      key: '/cases',
      icon: <DatabaseOutlined />,
      label: (
        <Link to={'/cases'}>
          <span>Cases</span>
        </Link>
      ),
    },
    {
      key: '/samples',
      icon: <ExperimentOutlined />,
      label: (
        <Link to={'/samples'}>
          <span>Samples</span>
        </Link>
      ),
    },
    {
      key: '/pools',
      icon: <DotChartOutlined />,
      label: (
        <Link to={'/pools'}>
          <span>Pools</span>
        </Link>
      ),
    },
    {
      key: '/account',
      icon: <SettingOutlined />,
      label: (
        <Link to={'/account'}>
          <span>Account</span>
        </Link>
      ),
    },
  ]
  useEffect(() => {
    getContent(contentfulUrl, contentfulToken, 'page', language)
      .then((pageResult) => {
        setAssets(pageResult.includes?.Asset)
        setPages(pageResult.items)
        setIsContentfulLoading(false)
        setIsContentfulLoading(false)
        getContent(contentfulUrl, contentfulToken, 'contactUs', language).then((postResults) => {
          setContacts(postResults.items)
        })
        /***
           * Commenting out News post
           *  getContent('newsPost', language)
           * .then((postResults) => {
           * setPosts(postResults.items)
          }) **/
      })
      .catch((error) => {
        setIsContentfulLoading(false)
        ErrorNotification(
          'Could not load content',
          'Some content is not accessible. Try again later'
        )
      })
  }, [contentfulUrl, contentfulToken])

  const renderAsset = (node) => {
    const currentAsset = assets.find((asset) => asset.sys.id === node.data.target.sys.id)
    if (!currentAsset) return null

    const { file, title } = currentAsset.fields || {}
    const { contentType, url } = file || {}

    if (contentType?.startsWith('image/')) {
      return <Image src={url} alt={title || 'Embedded Image'} />
    }

    if (contentType?.startsWith('video/')) {
      return (
        <video
          controls
          style={{
            maxWidth: '100%',
            maxHeight: '250px',
            objectFit: 'contain',
          }}
        >
          <source src={url} type={contentType} />
        </video>
      )
    }

    return null
  }

  const renderHeading = (level, node) => {
    const HeadingComponent = Title
    return <HeadingComponent level={level}>{node.content[0].value}</HeadingComponent>
  }

  const nodeRenderingOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: renderAsset,
      [BLOCKS.HEADING_1]: (node) => renderHeading(1, node),
      [BLOCKS.HEADING_2]: (node) => renderHeading(2, node),
      [BLOCKS.HEADING_3]: (node) => renderHeading(3, node),
      [BLOCKS.HEADING_4]: (node) => renderHeading(4, node),
      [BLOCKS.HR]: () => <Divider />,
    },
  }

  const PrivateRoute = ({ element: Element, ...rest }) => {
    return !isAuthenticated ? <Unauthorized /> : <Element {...rest} />
  }

  return (
    <>
      {isContentfulLoading ? (
        <Loading />
      ) : (
        <Layout style={{ minHeight: '100vh' }}>
          <UserContext.Provider value={{ token, user }}>
            {!acceptedCookiesPolicy && (
              <CookieConsent
                visible={isCookieDialogVisible}
                accept={acceptCookies}
                reject={rejectCookies}
              />
            )}
            <Header pages={pages} />
            <Layout>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <>
                    {!isNil(user) && (
                      <Sider collapsible={false} collapsed={true} className={styles.slider}>
                        <Menu
                          mode="inline"
                          defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          style={{ height: '100%', borderRight: 0, zIndex: 10 }}
                          selectedKeys={[location.pathname]}
                          items={sideMenuItems}
                        />
                      </Sider>
                    )}
                  </>
                  <Layout>
                    <Content style={{ padding: 30 }}>
                      <Routes>
                        <Route path="/" element={<HomePage user={user} />} />
                        <Route path="/callback" element={<HomePage user={user} />} />
                        <Route path="/contact-us" element={<ContactUs contacts={contacts} />} />
                        {/**Temporarily removing News<Route path="/news" element={<News posts={posts}/>}/>**/}
                        {pages.map((page) => {
                          return (
                            <Route
                              path={page?.fields?.title?.route}
                              key={page?.fields?.title?.route}
                              element={
                                <Card className={styles.contentful}>
                                  {documentToReactComponents(
                                    page.fields.content,
                                    nodeRenderingOptions
                                  )}
                                </Card>
                              }
                            />
                          )
                        })}
                        {!isLoading && (
                          <>
                            <Route path="/cases" element={<PrivateRoute element={CasesPage} />} />
                            <Route
                              path="/cases/:caseId"
                              element={<PrivateRoute element={CasePage} />}
                            />
                            <Route
                              path="/account"
                              element={<PrivateRoute element={AccountPage} />}
                            />
                            <Route
                              path="/samples"
                              element={<PrivateRoute element={SamplesPage} />}
                            />
                            <Route
                              path="/samples/:sampleId"
                              element={<PrivateRoute element={SamplePage} />}
                            />
                            <Route path="/pools" element={<PrivateRoute element={PoolsPage} />} />
                            <Route
                              path="/new-order"
                              element={
                                isServiceDown ? (
                                  <OrderServiceFailure />
                                ) : (
                                  <PrivateRoute element={OrderFormPage} />
                                )
                              }
                            />
                            <Route path="/applications" element={<ApplicationsPage />} />
                            <Route
                              path="/applications/:applicationId"
                              element={<ApplicationPage />}
                            />
                          </>
                        )}
                      </Routes>
                    </Content>
                    <Footer />
                  </Layout>
                </>
              )}
            </Layout>
          </UserContext.Provider>
        </Layout>
      )}
      <FloatButton.BackTop type="primary" />
    </>
  )
}
