import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Form, Select, Card, Badge, Spin, Alert, Row, Col, Tag } from 'antd'
import {
  carrierStatus,
  isBalsamic,
  isMIPDNA,
  isRNAFusion,
  isTomte,
  priorities,
  supportSystemURL,
  isMIPRNA,
} from '../../services/helpers/constants'
import localStyles from './Forms.module.css'
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons'
import { isNil } from 'ramda'
import { CaseSampleForm } from './CaseSampleForm'
import {
  buildExistingSample,
  getBackgroundColor,
  extractCaseValues,
} from '../../pages/OrderForm/helpers'
import { Case, Parents, Sample } from '../../services/interfaces'
import { DeletePopConfirm } from '../DeletePopConfirm'
import { UserContext } from '../../services/contexts/userContext'
import { ErrorNotification } from '../../services/helpers/helpers'
import { BalsamicCaseForm } from './CaseForms/BalsamicCaseForm'
import { TomteCaseForm } from './CaseForms/TomteCaseForm'
import { MIPDNACaseForm } from './CaseForms/MIPDNACaseForm'
import { RNAFusionCaseForm } from './CaseForms/RNAFusionCaseForm'
import { MIPRNACaseForm } from './CaseForms/MIPRNACaseForm'
import { useApi } from '../../services/StatusDbApi'

export const CaseForm = ({
  caseName,
  remove,
  options,
  form,
  customer,
  analysisType,
  skipReceptionControl,
  errors,
  applicationTags,
}) => {
  const [isFinalized, setIsFinalized] = useState<boolean>(false)
  const [isExisting, setIsExisting] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasSamples, setHasSamples] = useState<boolean>(true)
  const [hasSampleErrors, setHasSampleErrors] = useState<{ value: boolean; errors: any[] } | null>()
  const [parentsSamples, setParentSamples] = useState<Parents>({ mother: [], father: [] })
  const [finalizedCase, setFinalizedCase] = useState<Partial<Case>>()
  const [existingSamples, setExistingSamples] = useState<Partial<Sample[]>>([])
  const userContext = useContext(UserContext)
  const api = useApi()

  useEffect(() => {
    setIsLoading(false)
    if (errors?.errorFields?.length > 0) {
      finalizeCase(errors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  useEffect(() => {
    const localCase = form.getFieldValue('cases')[caseName]
    setIsExisting(!isNil(localCase?.internal_id?.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  const finalizeCase = useCallback(
    async ({ errorFields }: any) => {
      let hasCaseError = false
      const localCase = form.getFieldValue('cases')[caseName]
      if (localCase) {
        setHasSamples(localCase?.samples?.length > 0)
        errorFields?.map(({ name }) => {
          if (name[1] === caseName) {
            hasCaseError = true
          }
        })
        if (localCase?.sample_errors?.value === true) {
          hasCaseError = true
        }
        setIsFinalized(hasSamples ? !hasCaseError : false)
        setFinalizedCase(localCase)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, caseName, analysisType, skipReceptionControl, hasSamples, userContext]
  )

  const validateSaveCase = useCallback(() => {
    setIsLoading(true)
    const order = form.getFieldsValue()
    const extractedOrderValues = extractCaseValues(order)
    const hasSamples = extractedOrderValues?.cases[caseName]?.samples?.length > 0
    if (hasSamples) {
      api
        .validateOrder(userContext, analysisType, extractedOrderValues)
        .then((response) => {
          form.setFieldsValue(response)
          setHasSampleErrors(response.cases[caseName].sample_errors)
          return form.validateFields()
        })
        .then(() => finalizeCase([]))
        .catch((errors) => {
          finalizeCase(errors)
          setIsLoading(false)
        })
        .finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [userContext, finalizeCase, form, analysisType, caseName, api])

  const caseFieldsValidation = useCallback(() => {
    const order = form.getFieldsValue()
    const extractedOrderValues = extractCaseValues(order)
    function findErrors(samples: Record<string, any>) {
      const errors: { field: string; errors: any[] }[] = []
      for (const key in samples) {
        if (Object.prototype.hasOwnProperty.call(samples, key)) {
          const group = samples[key]
          for (const fieldKey in group) {
            if (Object.prototype.hasOwnProperty.call(group, fieldKey)) {
              const field = group[fieldKey]
              if (field.errors && Array.isArray(field.errors) && field.errors.length > 0) {
                errors.push({ field: fieldKey, errors: field.errors })
              }
            }
          }
        }
      }
      return errors.length > 0 ? errors : false
    }
    const hasErrors = findErrors(order?.cases[caseName]?.samples)

    if (!hasErrors) return

    api
      .validateOrder(userContext, analysisType, extractedOrderValues)
      .then((response) => {
        form.setFieldsValue(response)
        return form.validateFields()
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [userContext, form, analysisType, caseName, api])

  return (
    <Card
      key={caseName}
      bodyStyle={{
        backgroundColor: getBackgroundColor(caseName),
      }}
      style={{ margin: 3 }}
      id={`case ${caseName}`}
    >
      {isFinalized ? (
        <>
          <Row gutter={[16, 16]}>
            <Col flex="180px">
              <h2>{finalizedCase?.name?.value}</h2>
            </Col>
            <Col flex="120px">
              <Tag
                color={
                  priorities.find((priority) => priority.value === finalizedCase?.priority?.value)
                    ?.tagColor
                }
              >
                {priorities.find((priority) => priority.value === finalizedCase?.priority?.value)
                  ?.text || finalizedCase?.priority?.value}
              </Tag>
            </Col>
            <Col flex="auto">
              <div
                className={localStyles.savedCaseColumn}
                key={'finalized' + finalizedCase?.name?.value}
              >
                {finalizedCase?.samples?.value?.map((sample) => (
                  <React.Fragment key={sample.name}>
                    {isMIPDNA(analysisType) && (
                      <Badge
                        dot
                        key={sample.name}
                        status={
                          carrierStatus.find((carrier) => carrier.status === sample.status)
                            ?.badgeColor || 'default'
                        }
                      >
                        <div>{sample.name}</div>
                      </Badge>
                    )}
                    {isBalsamic(analysisType) && (
                      <div key={sample.name}>
                        {sample.tumour && <Tag color={'geekblue'}>T</Tag>}
                        {sample.name}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
            <Col flex="130px">
              {finalizedCase?.panels?.value?.map((panel) => (
                <Tag color="geekblue" key={panel}>
                  {panel}
                </Tag>
              ))}
            </Col>
            <Col flex="150px" style={{ textAlign: 'right' }}>
              <div className={localStyles.savedCaseColumn}>
                <DeletePopConfirm
                  itemType={'case'}
                  action={remove}
                  itemName={caseName}
                  triggerComponent={<Button icon={<DeleteTwoTone />}>Case</Button>}
                />
                <Button icon={<EditTwoTone />} onClick={() => setIsFinalized(false)}>
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Spin tip="Validating case..." size="small" spinning={isLoading}>
          {isExisting && (
            <Alert
              message="Existing case. Changes can affect and delay ongoing analysis.
Adding samples is not allowed. Create a new case to combine existing samples with new ones."
              type="info"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
          {hasSampleErrors?.value &&
            hasSampleErrors?.errors?.map((error, index) => (
              <Alert
                key={index}
                message={error}
                type="error"
                showIcon
                style={{ marginBottom: 12 }}
              />
            ))}
          {isBalsamic(analysisType) && (
            <BalsamicCaseForm
              caseName={caseName}
              isExisting={isExisting}
              priorities={priorities}
              validateCase={validateSaveCase}
              caseFieldsValidation={caseFieldsValidation}
              remove={remove}
              form={form}
            />
          )}

          {isTomte(analysisType) && (
            <TomteCaseForm
              caseName={caseName}
              isExisting={isExisting}
              priorities={priorities}
              validateCase={validateSaveCase}
              caseFieldsValidation={caseFieldsValidation}
              remove={remove}
              options={options}
              form={form}
            />
          )}

          {isMIPDNA(analysisType) && (
            <MIPDNACaseForm
              caseName={caseName}
              isExisting={isExisting}
              priorities={priorities}
              validateCase={validateSaveCase}
              caseFieldsValidation={caseFieldsValidation}
              remove={remove}
              options={options}
              form={form}
            />
          )}

          {isRNAFusion(analysisType) && (
            <RNAFusionCaseForm
              caseName={caseName}
              isExisting={isExisting}
              priorities={priorities}
              validateCase={validateSaveCase}
              caseFieldsValidation={caseFieldsValidation}
              remove={remove}
              form={form}
            />
          )}

          {isMIPRNA(analysisType) && (
            <MIPRNACaseForm
              caseName={caseName}
              isExisting={isExisting}
              priorities={priorities}
              validateCase={validateSaveCase}
              caseFieldsValidation={caseFieldsValidation}
              remove={remove}
              form={form}
            />
          )}
          <Form.List name={[caseName, 'samples']}>
            {(samples, { add, remove }) => (
              <>
                <Button
                  disabled={isExisting}
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  type={'primary'}
                  style={{ marginBottom: 5, marginRight: 20 }}
                >
                  New Sample
                </Button>
                Add existing sample:{' '}
                <Select
                  disabled={isExisting}
                  showSearch
                  value={null}
                  placeholder="Search and select to add sample"
                  onSearch={(searchInput) => {
                    if (searchInput.length > 2) {
                      api
                        .getExistingSamples(userContext, customer, searchInput)
                        .then(({ samples }) => setExistingSamples(() => samples))
                        .catch(() =>
                          ErrorNotification(
                            'Could not search for samples',
                            <div>
                              Something is wrong with the database. Open a ticket in the{' '}
                              <a href={`${supportSystemURL}home`}>support system</a> if the error
                              persists
                            </div>
                          )
                        )
                    }
                  }}
                  onChange={(sampleName) =>
                    add(
                      buildExistingSample(
                        existingSamples.find((sample) => sample?.internal_id === sampleName)
                      )
                    )
                  }
                  optionFilterProp={'label'}
                  options={
                    existingSamples?.map((existingSample) => {
                      return {
                        label:
                          existingSample?.name + ' (' + existingSample?.customer.internal_id + ')',
                        value: existingSample?.internal_id,
                      }
                    }) || []
                  }
                />
                {!hasSamples && (
                  <Alert message="Add at least one sample to the case" type="error" />
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                  {samples?.map(({ key, name }) => {
                    return (
                      <CaseSampleForm
                        key={name}
                        index={name}
                        remove={remove}
                        options={options}
                        form={form}
                        caseName={caseName}
                        parentsSamples={parentsSamples}
                        setParentSamples={setParentSamples}
                        analysisType={analysisType}
                        skipReceptionControl={skipReceptionControl}
                        applicationTags={applicationTags}
                        caseFieldsValidation={caseFieldsValidation}
                      />
                    )
                  })}
                </div>
              </>
            )}
          </Form.List>
        </Spin>
      )}
    </Card>
  )
}
