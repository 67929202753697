import React from 'react'
import { Layout, Menu } from 'antd'
import { AuthButton } from 'components/AuthButton/AuthButton'
import Logo from '../../assets/logo.svg'
import styles from './Header.module.css'
import { Link } from 'react-router-dom'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { HospitalHeader } from './HospitalHeader'

export const Header = ({ pages }) => {
  const { Header } = Layout
  const IS_HOSPITAL = true
  const topMenuItems = [
    {
      key: '/',
      label: (
        <Link to={'/'}>
          <span>Home</span>
        </Link>
      ),
    },
    ...pages.map((page) => ({
      key: `/${page?.fields?.title?.route}`,
      label: (
        <Link to={page?.fields?.title?.route}>
          <span>{page?.fields?.title?.title}</span>
        </Link>
      ),
    })),
    {
      key: '/applications',
      label: (
        <Link to={'/applications'}>
          <span>Applications</span>
        </Link>
      ),
    },
    {
      key: '/contact-us',
      label: (
        <Link to={'/contact-us'}>
          <span>Contact Us</span>
        </Link>
      ),
    },
  ]

  return IS_HOSPITAL ? (
    <HospitalHeader pages={pages} />
  ) : (
    <div className={styles.headerContainer}>
      <img className={styles.logo} src={Logo} alt="Small CG logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[location.pathname]}
        items={topMenuItems}
        className={styles.menu}
      />
      <AuthButton />
    </div>
  )
}
