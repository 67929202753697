import React from 'react'
import { useAuth } from 'react-oidc-context'
import { ErrorNotification } from '../services/helpers/helpers'
import { User } from 'services/interfaces'

export const useOidcAuth = () => {
  const auth = useAuth()

  React.useEffect(() => {
    if (auth?.error) {
      ErrorNotification('Could not load content', auth.error.message)
    }
  }, [auth?.error])

  return React.useMemo(
    () => ({
      user: auth?.user?.profile ? (auth?.user?.profile as User) : null,
      token: auth?.user?.access_token || null,
      isAuthenticated: !!auth?.user,
      login: auth?.signinRedirect ?? (() => console.warn('signinRedirect is not available')),
      logout: () => auth.signoutRedirect({ post_logout_redirect_uri: window.location.origin }),
      error: auth?.error || null,
      isLoading: auth?.isLoading || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  )
}
