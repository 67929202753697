import React from 'react'
import { Alert, Button, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { StandaloneSampleForm } from './StandaloneSampleForm'

export const StandaloneSampleFormList = ({
  selectedAnalysisType,
  form,
  options,
  validationError,
  skipReceptionControl,
  validatingOrder,
  applicationTags,
}) => {
  if (validatingOrder) {
    return null
  }

  return (
    <Form.List name={'samples'}>
      {(samples, { add, remove }) => (
        <>
          <Button
            onClick={() => add()}
            icon={<PlusOutlined />}
            type={'primary'}
            style={{ marginBottom: 5, marginRight: 20 }}
          >
            New Sample
          </Button>
          {validationError?.hasError && (
            <>
              {validationError?.messages?.map((message) => (
                <Alert key={message} message={message} type="error" />
              ))}
            </>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
            {samples?.map(({ key, name }) => {
              return (
                <StandaloneSampleForm
                  key={key}
                  index={name}
                  remove={() => remove(name)}
                  options={options}
                  form={form}
                  analysisType={selectedAnalysisType}
                  skipReceptionControl={skipReceptionControl}
                  applicationTags={applicationTags}
                />
              )
            })}
          </div>
        </>
      )}
    </Form.List>
  )
}
